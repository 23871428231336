<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-col-gutter-md">
        <q-select
          :options="listPembina"
          v-model="bundleEkskul.pembina"
          map-options
          emit-value
          outlined
          label="Pembina"
        ></q-select>
        <q-input
          stack-label
          outlined
          v-model="bundleEkskul.pelatih"
          label="Nama Pelatih"
        ></q-input>
        <q-input
          stack-label
          outlined
          v-model="bundleEkskul.kontak"
          label="No.HP pelatih"
        ></q-input>
        <q-input
          stack-label
          outlined
          :readonly="this.idEkskul > 0"
          v-model="bundleEkskul.nama"
          label="Nama Ekskul"
        ></q-input>
      </q-card-section>
      <q-card-actions class="row justify-end">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn
          v-if="idEkskul < 0"
          unelevated
          color="primary"
          @click="createEkskul"
          :disable="bundleEkskul.pembina == null || bundleEkskul.nama == null"
          >Tambah</q-btn
        >
        <q-btn v-else color="primary" @click="edit">Edit</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["idEkskul"],
  data() {
    return {
      listPembina: [],
      bundleEkskul: {
        pembina: null,
        pelatih: null,
        kontak: null,
        nama: null
      }
    };
  },
  async mounted() {
    await this.getPembinaList();
    if (this.idEkskul > 0) {
      this.getEKskulDetail();
    }
  },
  methods: {
    edit() {
      if (this.bundleEkskul.nama == "") {
        this.$q.notify.show({
          message: "Nama Ekskul Tidak Boleh Kosong",
          color: "negative"
        });
        return;
      }
      this.bundleEkskul.pembina = this.bundleEkskul.pembina.value;
      this.$http
        .put(`/pengaturan/ekskul/edit`, this.bundleEkskul)
        .then(resp => {
          this.onOKClick();
        });
    },
    getEKskulDetail() {
      this.$http
        .get(`/pengaturan/ekskul/getdetail/${this.idEkskul}`)
        .then(resp => {
          resp.data.pembina = {
            label: resp.data.nama_guru,
            value: resp.data.id_guru
          };
          this.bundleEkskul = resp.data;
        });
    },
    async getPembinaList() {
      await this.$http.get(`/pengaturan/ekskul/getpembina`).then(resp => {
        this.listPembina = resp.data;
      });
    },
    createEkskul() {
      if (this.bundleEkskul.nama == "") {
        this.$q.notify.show({
          message: "Nama Ekskul Tidak Boleh Kosong",
          color: "negative"
        });
        return;
      }
      this.bundleEkskul.jenjang = localStorage.getItem("jenjang");
      this.$http
        .post(`/pengaturan/ekskul/addekskul`, this.bundleEkskul)
        .then(resp => {
          this.onOKClick();
        });
    },
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      this.$emit("ok");
      this.hide();
    },
    onCancelClick() {
      this.hide();
    }
  }
};
</script>
