<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row q-gutter-sm">
          <q-btn
            icon="add"
            color="primary"
            label="Tambah Ekskul"
            @click="showDialogEdit(-1)"
            v-can="['KURIKULUM']"
          ></q-btn>
        </div>
        <q-input outlined label="Cari Ekskul" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>No</th>
            <th>Nama Ekskul</th>
            <th>Pembina</th>
            <th>Pelatih</th>
            <th>Kontak</th>
            <th></th>
            <th style="width:20px;"></th>
          </tr>
        </thead>
        <tbody class="bg-indigo-1">
          <tr v-for="(val, i) in filEkskul" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nama }}</td>
            <td>{{ val.pembina }}</td>
            <td>{{ val.pelatih }}</td>
            <td>{{ val.kontak }}</td>
            <td>
              <q-btn
                flat
                v-can="['EKSKUL', 'WALAS']"
                :to="`/pengaturanisiekskul/${val.id}/${val.nama}`"
                icon="add"
                label="Tambah Siswa"
                color="primary"
              ></q-btn>
            </td>
            <td class="q-gutter-sm">
              <q-btn
                flat
                dense
                v-can="['KURIKULUM']"
                icon="edit"
                color="secondary"
                @click="showDialogEdit(val.id)"
              ></q-btn>
              <!-- <q-btn
                flat
                dense
                @click="
                  (toDel = { id: val.id, nama: val.nama }), (confirm = true)
                "
                color="red"
                icon="delete_forever"
              /> -->
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
    <q-dialog v-model="confirm" persistent>
      <q-card class="bg-negative text-white">
        <q-card-section class="row items-center">
          <strong class="q-ml-sm"
            >Apa anda yakin ingin menghapus Ekskul "{{ toDel.nama }}" ?</strong
          >
          <strong class="q-ml-sm"
            >Data yang Anda Hapus akan berpengaruh terhadap nilai siswa yang
            sudah di inputkan</strong
          >
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Tidak" color="white" v-close-popup />
          <q-btn
            flat
            label="Ya"
            color="white"
            @click="deleteData(toDel.id)"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogEditEkskul from "@/components/DialogEditEkskul";
export default {
  data() {
    return {
      toDel: {
        id: 0,
        name: "",
      },
      confirm: false,
      searchTerm: "",
      listTingkat: ["7", "8", "9"],
      selTingkat: "7",
      ekskul: [],
    };
  },
  computed: {
    filEkskul() {
      if (this.searchTerm == "") return this.ekskul;
      else {
        return this.ekskul.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  async mounted() {
    this.getListEkskul();
  },
  methods: {
    getListEkskul() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let jenjang = localStorage.getItem("jenjang");
      this.$http.get(`/pengaturan/ekskul/getlist/${jenjang}`).then((resp) => {
        this.ekskul = resp.data;
      });
      this.$q.loading.hide();
    },
    showDialogEdit(id) {
      this.$q
        .dialog({
          component: DialogEditEkskul,
          parent: this,
          idEkskul: id,
        })
        .onOk((resp) => {
          this.getListEkskul();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
