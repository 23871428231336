import { render, staticRenderFns } from "./PengaturanEkskul.vue?vue&type=template&id=bb77333e&scoped=true&"
import script from "./PengaturanEkskul.vue?vue&type=script&lang=js&"
export * from "./PengaturanEkskul.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb77333e",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QBtn,QInput,QIcon,QMarkupTable,QDialog,QCardActions});qInstall(component, 'directives', {ClosePopup});
